
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as competition_45privacy_45policy_45gwagon3DfwsLi1muMeta } from "/home/github-runner/actions-runner/_work/injective-helix/injective-helix/pages/competition-privacy-policy-gwagon.vue?macro=true";
import { default as competition_45privacy_45policy_45own_45your_45assetskMAvMaWTv0Meta } from "/home/github-runner/actions-runner/_work/injective-helix/injective-helix/pages/competition-privacy-policy-own-your-assets.vue?macro=true";
import { default as competition_45privacy_45policy_45teslakPYHrDdfNCMeta } from "/home/github-runner/actions-runner/_work/injective-helix/injective-helix/pages/competition-privacy-policy-tesla.vue?macro=true";
import { default as envPEQvf2ZQj7Meta } from "/home/github-runner/actions-runner/_work/injective-helix/injective-helix/pages/env.vue?macro=true";
import { default as fee_45discountsX8flgjfBw0Meta } from "/home/github-runner/actions-runner/_work/injective-helix/injective-helix/pages/fee-discounts.vue?macro=true";
import { default as futures3F6hR9f9VQMeta } from "/home/github-runner/actions-runner/_work/injective-helix/injective-helix/pages/futures.vue?macro=true";
import { default as _91guild_93IZ73NYumkTMeta } from "/home/github-runner/actions-runner/_work/injective-helix/injective-helix/pages/guild/[guild].vue?macro=true";
import { default as guildsxt7eeLxDKlMeta } from "/home/github-runner/actions-runner/_work/injective-helix/injective-helix/pages/guilds.vue?macro=true";
import { default as index190vWWKVAhMeta } from "/home/github-runner/actions-runner/_work/injective-helix/injective-helix/pages/index.vue?macro=true";
import { default as institutionalSYfRkGmyRbMeta } from "/home/github-runner/actions-runner/_work/injective-helix/injective-helix/pages/institutional.vue?macro=true";
import { default as competitionfNi334u9VrMeta } from "/home/github-runner/actions-runner/_work/injective-helix/injective-helix/pages/leaderboard/competition.vue?macro=true";
import { default as indexMuyLTsvSd2Meta } from "/home/github-runner/actions-runner/_work/injective-helix/injective-helix/pages/leaderboard/index.vue?macro=true";
import { default as past_45competitionsQlQcSnOWCdMeta } from "/home/github-runner/actions-runner/_work/injective-helix/injective-helix/pages/leaderboard/past-competitions.vue?macro=true";
import { default as leaderboardWEtvXt6wZpMeta } from "/home/github-runner/actions-runner/_work/injective-helix/injective-helix/pages/leaderboard.vue?macro=true";
import { default as like_45a_45g_45competition_45termsgcQkibAoKkMeta } from "/home/github-runner/actions-runner/_work/injective-helix/injective-helix/pages/like-a-g-competition-terms.vue?macro=true";
import { default as campaignNAwFYip9VdMeta } from "/home/github-runner/actions-runner/_work/injective-helix/injective-helix/pages/lp-rewards/campaign.vue?macro=true";
import { default as dashboardbid3wBFwnuMeta } from "/home/github-runner/actions-runner/_work/injective-helix/injective-helix/pages/lp-rewards/dashboard.vue?macro=true";
import { default as indexNfszO7LwSbMeta } from "/home/github-runner/actions-runner/_work/injective-helix/injective-helix/pages/lp-rewards/index.vue?macro=true";
import { default as lp_45rewardsr19A10jbCoMeta } from "/home/github-runner/actions-runner/_work/injective-helix/injective-helix/pages/lp-rewards.vue?macro=true";
import { default as maintenance3mcbuKYqsnMeta } from "/home/github-runner/actions-runner/_work/injective-helix/injective-helix/pages/maintenance.vue?macro=true";
import { default as marketsBLbfC98xuVMeta } from "/home/github-runner/actions-runner/_work/injective-helix/injective-helix/pages/markets.vue?macro=true";
import { default as own_45your_45assets_45competition_45termslSej77kdyjMeta } from "/home/github-runner/actions-runner/_work/injective-helix/injective-helix/pages/own-your-assets-competition-terms.vue?macro=true";
import { default as playgroundkcah9dIYelMeta } from "/home/github-runner/actions-runner/_work/injective-helix/injective-helix/pages/playground.vue?macro=true";
import { default as pointsjfmgONZ9znMeta } from "/home/github-runner/actions-runner/_work/injective-helix/injective-helix/pages/points.vue?macro=true";
import { default as indexg1oFnFGS7XMeta } from "/home/github-runner/actions-runner/_work/injective-helix/injective-helix/pages/portfolio/balances/index.vue?macro=true";
import { default as indexAlEvSGUEuTMeta } from "/home/github-runner/actions-runner/_work/injective-helix/injective-helix/pages/portfolio/history/funding-history/index.vue?macro=true";
import { default as indexwlvinQymfUMeta } from "/home/github-runner/actions-runner/_work/injective-helix/injective-helix/pages/portfolio/history/swap/index.vue?macro=true";
import { default as index8JOoYlhNXKMeta } from "/home/github-runner/actions-runner/_work/injective-helix/injective-helix/pages/portfolio/history/wallet/index.vue?macro=true";
import { default as index5A1OqnUJycMeta } from "/home/github-runner/actions-runner/_work/injective-helix/injective-helix/pages/portfolio/index.vue?macro=true";
import { default as futures_45grid0PeDcuZzJXMeta } from "/home/github-runner/actions-runner/_work/injective-helix/injective-helix/pages/portfolio/orders/futures-grid.vue?macro=true";
import { default as advanced_45ordersIiw6khW1yeMeta } from "/home/github-runner/actions-runner/_work/injective-helix/injective-helix/pages/portfolio/orders/futures/advanced-orders.vue?macro=true";
import { default as indexYWzHvpGllYMeta } from "/home/github-runner/actions-runner/_work/injective-helix/injective-helix/pages/portfolio/orders/futures/index.vue?macro=true";
import { default as order_45historyrMLhgFBaqpMeta } from "/home/github-runner/actions-runner/_work/injective-helix/injective-helix/pages/portfolio/orders/futures/order-history.vue?macro=true";
import { default as trade_45historyXsdqWTftR2Meta } from "/home/github-runner/actions-runner/_work/injective-helix/injective-helix/pages/portfolio/orders/futures/trade-history.vue?macro=true";
import { default as futuresU6LQgtEHuXMeta } from "/home/github-runner/actions-runner/_work/injective-helix/injective-helix/pages/portfolio/orders/futures.vue?macro=true";
import { default as spot_45gridamPCctNroaMeta } from "/home/github-runner/actions-runner/_work/injective-helix/injective-helix/pages/portfolio/orders/spot-grid.vue?macro=true";
import { default as indexJ2M0LKZ8o8Meta } from "/home/github-runner/actions-runner/_work/injective-helix/injective-helix/pages/portfolio/orders/spot/index.vue?macro=true";
import { default as order_45historyywdpIlM9qcMeta } from "/home/github-runner/actions-runner/_work/injective-helix/injective-helix/pages/portfolio/orders/spot/order-history.vue?macro=true";
import { default as trade_45historyHIKmK6u1xPMeta } from "/home/github-runner/actions-runner/_work/injective-helix/injective-helix/pages/portfolio/orders/spot/trade-history.vue?macro=true";
import { default as spot2EfWzPGVOFMeta } from "/home/github-runner/actions-runner/_work/injective-helix/injective-helix/pages/portfolio/orders/spot.vue?macro=true";
import { default as indexIXb3cmtq3lMeta } from "/home/github-runner/actions-runner/_work/injective-helix/injective-helix/pages/portfolio/positions/index.vue?macro=true";
import { default as granterWezFuXRVQeMeta } from "/home/github-runner/actions-runner/_work/injective-helix/injective-helix/pages/portfolio/settings/authz/granter.vue?macro=true";
import { default as indexCFHCPfKb3kMeta } from "/home/github-runner/actions-runner/_work/injective-helix/injective-helix/pages/portfolio/settings/authz/index.vue?macro=true";
import { default as authzCkk1uG5AjuMeta } from "/home/github-runner/actions-runner/_work/injective-helix/injective-helix/pages/portfolio/settings/authz.vue?macro=true";
import { default as autosignGjgqwpn1m7Meta } from "/home/github-runner/actions-runner/_work/injective-helix/injective-helix/pages/portfolio/settings/autosign.vue?macro=true";
import { default as indexQkI0jXaF5WMeta } from "/home/github-runner/actions-runner/_work/injective-helix/injective-helix/pages/portfolio/settings/index.vue?macro=true";
import { default as preferencescznYk52AKSMeta } from "/home/github-runner/actions-runner/_work/injective-helix/injective-helix/pages/portfolio/settings/preferences.vue?macro=true";
import { default as indexa4NfdOmSKFMeta } from "/home/github-runner/actions-runner/_work/injective-helix/injective-helix/pages/portfolio/subaccounts/index.vue?macro=true";
import { default as portfolionKC6doDDDyMeta } from "/home/github-runner/actions-runner/_work/injective-helix/injective-helix/pages/portfolio.vue?macro=true";
import { default as spotXW1Jhk91zHMeta } from "/home/github-runner/actions-runner/_work/injective-helix/injective-helix/pages/spot.vue?macro=true";
import { default as swap9uDO3SWAsyMeta } from "/home/github-runner/actions-runner/_work/injective-helix/injective-helix/pages/swap.vue?macro=true";
import { default as termsnNcAaESanmMeta } from "/home/github-runner/actions-runner/_work/injective-helix/injective-helix/pages/terms.vue?macro=true";
import { default as tesla_45competition_45terms6WHmK8C3wnMeta } from "/home/github-runner/actions-runner/_work/injective-helix/injective-helix/pages/tesla-competition-terms.vue?macro=true";
import { default as trade_45and_45earn5cAcX1wc0OMeta } from "/home/github-runner/actions-runner/_work/injective-helix/injective-helix/pages/trade-and-earn.vue?macro=true";
import { default as indexCH8dbz9H8WMeta } from "/home/github-runner/actions-runner/_work/injective-helix/injective-helix/pages/trading-bots/index.vue?macro=true";
import { default as indexqAy73qON9UMeta } from "/home/github-runner/actions-runner/_work/injective-helix/injective-helix/pages/trading-bots/liquidity-bots/spot/index.vue?macro=true";
import { default as transferK1KWnzbCnLMeta } from "/home/github-runner/actions-runner/_work/injective-helix/injective-helix/pages/transfer.vue?macro=true";
import { default as vaultsN9RM47I4UTMeta } from "/home/github-runner/actions-runner/_work/injective-helix/injective-helix/pages/vaults.vue?macro=true";
export default [
  {
    name: "competition-privacy-policy-gwagon",
    path: "/competition-privacy-policy-gwagon",
    component: () => import("/home/github-runner/actions-runner/_work/injective-helix/injective-helix/pages/competition-privacy-policy-gwagon.vue")
  },
  {
    name: "competition-privacy-policy-own-your-assets",
    path: "/competition-privacy-policy-own-your-assets",
    component: () => import("/home/github-runner/actions-runner/_work/injective-helix/injective-helix/pages/competition-privacy-policy-own-your-assets.vue")
  },
  {
    name: "competition-privacy-policy-tesla",
    path: "/competition-privacy-policy-tesla",
    component: () => import("/home/github-runner/actions-runner/_work/injective-helix/injective-helix/pages/competition-privacy-policy-tesla.vue")
  },
  {
    name: "env",
    path: "/env",
    meta: envPEQvf2ZQj7Meta || {},
    component: () => import("/home/github-runner/actions-runner/_work/injective-helix/injective-helix/pages/env.vue")
  },
  {
    name: "fee-discounts",
    path: "/fee-discounts",
    component: () => import("/home/github-runner/actions-runner/_work/injective-helix/injective-helix/pages/fee-discounts.vue")
  },
  {
    name: "futures",
    path: "/futures",
    component: () => import("/home/github-runner/actions-runner/_work/injective-helix/injective-helix/pages/futures.vue")
  },
  {
    name: "guild-guild",
    path: "/guild/:guild()",
    component: () => import("/home/github-runner/actions-runner/_work/injective-helix/injective-helix/pages/guild/[guild].vue")
  },
  {
    name: "guilds",
    path: "/guilds",
    component: () => import("/home/github-runner/actions-runner/_work/injective-helix/injective-helix/pages/guilds.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/github-runner/actions-runner/_work/injective-helix/injective-helix/pages/index.vue")
  },
  {
    name: "institutional",
    path: "/institutional",
    meta: institutionalSYfRkGmyRbMeta || {},
    component: () => import("/home/github-runner/actions-runner/_work/injective-helix/injective-helix/pages/institutional.vue")
  },
  {
    name: leaderboardWEtvXt6wZpMeta?.name,
    path: "/leaderboard",
    component: () => import("/home/github-runner/actions-runner/_work/injective-helix/injective-helix/pages/leaderboard.vue"),
    children: [
  {
    name: "leaderboard-competition",
    path: "competition",
    component: () => import("/home/github-runner/actions-runner/_work/injective-helix/injective-helix/pages/leaderboard/competition.vue")
  },
  {
    name: "leaderboard",
    path: "",
    component: () => import("/home/github-runner/actions-runner/_work/injective-helix/injective-helix/pages/leaderboard/index.vue")
  },
  {
    name: "leaderboard-past-competitions",
    path: "past-competitions",
    component: () => import("/home/github-runner/actions-runner/_work/injective-helix/injective-helix/pages/leaderboard/past-competitions.vue")
  }
]
  },
  {
    name: "like-a-g-competition-terms",
    path: "/like-a-g-competition-terms",
    component: () => import("/home/github-runner/actions-runner/_work/injective-helix/injective-helix/pages/like-a-g-competition-terms.vue")
  },
  {
    name: lp_45rewardsr19A10jbCoMeta?.name,
    path: "/lp-rewards",
    component: () => import("/home/github-runner/actions-runner/_work/injective-helix/injective-helix/pages/lp-rewards.vue"),
    children: [
  {
    name: "lp-rewards-campaign",
    path: "campaign",
    component: () => import("/home/github-runner/actions-runner/_work/injective-helix/injective-helix/pages/lp-rewards/campaign.vue")
  },
  {
    name: "lp-rewards-dashboard",
    path: "dashboard",
    component: () => import("/home/github-runner/actions-runner/_work/injective-helix/injective-helix/pages/lp-rewards/dashboard.vue")
  },
  {
    name: "lp-rewards",
    path: "",
    component: () => import("/home/github-runner/actions-runner/_work/injective-helix/injective-helix/pages/lp-rewards/index.vue")
  }
]
  },
  {
    name: "maintenance",
    path: "/maintenance",
    meta: maintenance3mcbuKYqsnMeta || {},
    component: () => import("/home/github-runner/actions-runner/_work/injective-helix/injective-helix/pages/maintenance.vue")
  },
  {
    name: "markets",
    path: "/markets",
    component: () => import("/home/github-runner/actions-runner/_work/injective-helix/injective-helix/pages/markets.vue")
  },
  {
    name: "own-your-assets-competition-terms",
    path: "/own-your-assets-competition-terms",
    component: () => import("/home/github-runner/actions-runner/_work/injective-helix/injective-helix/pages/own-your-assets-competition-terms.vue")
  },
  {
    name: "playground",
    path: "/playground",
    meta: playgroundkcah9dIYelMeta || {},
    component: () => import("/home/github-runner/actions-runner/_work/injective-helix/injective-helix/pages/playground.vue")
  },
  {
    name: "points",
    path: "/points",
    component: () => import("/home/github-runner/actions-runner/_work/injective-helix/injective-helix/pages/points.vue")
  },
  {
    name: portfolionKC6doDDDyMeta?.name,
    path: "/portfolio",
    meta: portfolionKC6doDDDyMeta || {},
    component: () => import("/home/github-runner/actions-runner/_work/injective-helix/injective-helix/pages/portfolio.vue"),
    children: [
  {
    name: "portfolio-balances",
    path: "balances",
    component: () => import("/home/github-runner/actions-runner/_work/injective-helix/injective-helix/pages/portfolio/balances/index.vue")
  },
  {
    name: "portfolio-history-funding-history",
    path: "history/funding-history",
    component: () => import("/home/github-runner/actions-runner/_work/injective-helix/injective-helix/pages/portfolio/history/funding-history/index.vue")
  },
  {
    name: "portfolio-history-swap",
    path: "history/swap",
    component: () => import("/home/github-runner/actions-runner/_work/injective-helix/injective-helix/pages/portfolio/history/swap/index.vue")
  },
  {
    name: "portfolio-history-wallet",
    path: "history/wallet",
    component: () => import("/home/github-runner/actions-runner/_work/injective-helix/injective-helix/pages/portfolio/history/wallet/index.vue")
  },
  {
    name: "portfolio",
    path: "",
    component: () => import("/home/github-runner/actions-runner/_work/injective-helix/injective-helix/pages/portfolio/index.vue")
  },
  {
    name: "portfolio-orders-futures-grid",
    path: "orders/futures-grid",
    component: () => import("/home/github-runner/actions-runner/_work/injective-helix/injective-helix/pages/portfolio/orders/futures-grid.vue")
  },
  {
    name: futuresU6LQgtEHuXMeta?.name,
    path: "orders/futures",
    component: () => import("/home/github-runner/actions-runner/_work/injective-helix/injective-helix/pages/portfolio/orders/futures.vue"),
    children: [
  {
    name: "portfolio-orders-futures-advanced-orders",
    path: "advanced-orders",
    component: () => import("/home/github-runner/actions-runner/_work/injective-helix/injective-helix/pages/portfolio/orders/futures/advanced-orders.vue")
  },
  {
    name: "portfolio-orders-futures",
    path: "",
    component: () => import("/home/github-runner/actions-runner/_work/injective-helix/injective-helix/pages/portfolio/orders/futures/index.vue")
  },
  {
    name: "portfolio-orders-futures-order-history",
    path: "order-history",
    component: () => import("/home/github-runner/actions-runner/_work/injective-helix/injective-helix/pages/portfolio/orders/futures/order-history.vue")
  },
  {
    name: "portfolio-orders-futures-trade-history",
    path: "trade-history",
    component: () => import("/home/github-runner/actions-runner/_work/injective-helix/injective-helix/pages/portfolio/orders/futures/trade-history.vue")
  }
]
  },
  {
    name: "portfolio-orders-spot-grid",
    path: "orders/spot-grid",
    component: () => import("/home/github-runner/actions-runner/_work/injective-helix/injective-helix/pages/portfolio/orders/spot-grid.vue")
  },
  {
    name: spot2EfWzPGVOFMeta?.name,
    path: "orders/spot",
    component: () => import("/home/github-runner/actions-runner/_work/injective-helix/injective-helix/pages/portfolio/orders/spot.vue"),
    children: [
  {
    name: "portfolio-orders-spot",
    path: "",
    component: () => import("/home/github-runner/actions-runner/_work/injective-helix/injective-helix/pages/portfolio/orders/spot/index.vue")
  },
  {
    name: "portfolio-orders-spot-order-history",
    path: "order-history",
    component: () => import("/home/github-runner/actions-runner/_work/injective-helix/injective-helix/pages/portfolio/orders/spot/order-history.vue")
  },
  {
    name: "portfolio-orders-spot-trade-history",
    path: "trade-history",
    component: () => import("/home/github-runner/actions-runner/_work/injective-helix/injective-helix/pages/portfolio/orders/spot/trade-history.vue")
  }
]
  },
  {
    name: "portfolio-positions",
    path: "positions",
    component: () => import("/home/github-runner/actions-runner/_work/injective-helix/injective-helix/pages/portfolio/positions/index.vue")
  },
  {
    name: authzCkk1uG5AjuMeta?.name,
    path: "settings/authz",
    component: () => import("/home/github-runner/actions-runner/_work/injective-helix/injective-helix/pages/portfolio/settings/authz.vue"),
    children: [
  {
    name: "portfolio-settings-authz-granter",
    path: "granter",
    component: () => import("/home/github-runner/actions-runner/_work/injective-helix/injective-helix/pages/portfolio/settings/authz/granter.vue")
  },
  {
    name: "portfolio-settings-authz",
    path: "",
    component: () => import("/home/github-runner/actions-runner/_work/injective-helix/injective-helix/pages/portfolio/settings/authz/index.vue")
  }
]
  },
  {
    name: "portfolio-settings-autosign",
    path: "settings/autosign",
    component: () => import("/home/github-runner/actions-runner/_work/injective-helix/injective-helix/pages/portfolio/settings/autosign.vue")
  },
  {
    name: "portfolio-settings",
    path: "settings",
    component: () => import("/home/github-runner/actions-runner/_work/injective-helix/injective-helix/pages/portfolio/settings/index.vue")
  },
  {
    name: "portfolio-settings-preferences",
    path: "settings/preferences",
    component: () => import("/home/github-runner/actions-runner/_work/injective-helix/injective-helix/pages/portfolio/settings/preferences.vue")
  },
  {
    name: "portfolio-subaccounts",
    path: "subaccounts",
    component: () => import("/home/github-runner/actions-runner/_work/injective-helix/injective-helix/pages/portfolio/subaccounts/index.vue")
  }
]
  },
  {
    name: "spot",
    path: "/spot",
    component: () => import("/home/github-runner/actions-runner/_work/injective-helix/injective-helix/pages/spot.vue")
  },
  {
    name: "swap",
    path: "/swap",
    meta: swap9uDO3SWAsyMeta || {},
    component: () => import("/home/github-runner/actions-runner/_work/injective-helix/injective-helix/pages/swap.vue")
  },
  {
    name: "terms",
    path: "/terms",
    component: () => import("/home/github-runner/actions-runner/_work/injective-helix/injective-helix/pages/terms.vue")
  },
  {
    name: "tesla-competition-terms",
    path: "/tesla-competition-terms",
    component: () => import("/home/github-runner/actions-runner/_work/injective-helix/injective-helix/pages/tesla-competition-terms.vue")
  },
  {
    name: "trade-and-earn",
    path: "/trade-and-earn",
    component: () => import("/home/github-runner/actions-runner/_work/injective-helix/injective-helix/pages/trade-and-earn.vue")
  },
  {
    name: "trading-bots",
    path: "/trading-bots",
    component: () => import("/home/github-runner/actions-runner/_work/injective-helix/injective-helix/pages/trading-bots/index.vue")
  },
  {
    name: "trading-bots-liquidity-bots-spot",
    path: "/trading-bots/liquidity-bots/spot",
    component: () => import("/home/github-runner/actions-runner/_work/injective-helix/injective-helix/pages/trading-bots/liquidity-bots/spot/index.vue")
  },
  {
    name: "transfer",
    path: "/transfer",
    component: () => import("/home/github-runner/actions-runner/_work/injective-helix/injective-helix/pages/transfer.vue")
  },
  {
    name: "vaults",
    path: "/vaults",
    component: () => import("/home/github-runner/actions-runner/_work/injective-helix/injective-helix/pages/vaults.vue")
  },
  {
    name: "futures-slug",
    path: "/futures/:slug()",
    component: () => import("/home/github-runner/actions-runner/_work/injective-helix/injective-helix/pages/futures.vue")
  },
  {
    name: "spot-slug",
    path: "/spot/:slug()",
    component: () => import("/home/github-runner/actions-runner/_work/injective-helix/injective-helix/pages/spot.vue")
  }
]